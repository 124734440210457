<template>
  <div class="home">
    <div class="l-width">
      <h2>Find the closest LEGO&trade; color to a hex code.</h2>
      <form class="search-form" @submit.prevent="findColors">
        <label class="search-form__label" for="color-search">Hex Code</label>

        <input
          class="search-form__input"
          id="color-search"
          name="color-search"
          type="text"
          v-model="userColor"
        />
        <ColorDetail :color="userColorObject" />
        <button type="submit" class="search-form__submit button">Search</button>
      </form>
      <!-- <div class="l-two-col"> -->
      <template v-if="closestColors && closestColors.length">
        <h3>Closest colors</h3>
        <ColorExclusions />
        <div class="colors-list" v-if="closestColors && closestColors.length">
          <ColorDetail
            v-for="color in closestColors"
            :key="color"
            :color="color"
          />
        </div>
      </template>
      <!-- <div v-if="foundColor">
          <ColorDetail :color="foundColor" />
        </div> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import ColorDetail from '@/components/ColorDetail.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { isColor } from '@/utilities/color';
import ColorExclusions from '@/components/ColorExclusions.vue';

export default {
  name: 'SearchView',

  components: { ColorDetail, ColorExclusions },

  data() {
    return {
      userColor: '',
    };
  },

  computed: {
    ...mapState(['foundColor']),

    ...mapGetters(['closestColors']),

    isValidColor() {
      return isColor(this.userColor);
    },

    userColorObject() {
      return {
        color_name: 'Custom color',
        color_code: this.isValidColor ? this.userColor.replace('#', '') : 'ddd',
      };
    },
  },

  methods: {
    ...mapActions(['getClosestColors', 'findClosestColors', 'getColors']),

    findColors() {
      this.getClosestColors(this.userColor);
      // this.findClosestColors(this.userColor);
    },
  },

  async created() {
    this.getColors();
  },
};
</script>

<style lang="scss" scoped>
.search-form {
  display: grid;
  grid-template:
    'label color' auto
    'input color' auto
    'button color' auto /
    1fr 8rem;
  gap: 0 2rem;

  .part-color {
    grid-area: color;
    align-self: center;
  }
}

.search-form__label {
  grid-area: label;
}

.search-form__input {
  grid-area: input;
}

.search-form__submit {
  grid-area: button;
}

.colors-list {
  margin: 0 0 1rem;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  gap: 1rem;
}

.l-two-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
