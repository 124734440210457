<template>
  <form>
    <label for="parts-search">Search for Parts</label>

    <input
      id="parts-search"
      name="parts-search"
      type="text"
      v-model="search"
      @input="searchParts"
    />

    <div class="parts-results">
      <LoadingSpinner v-if="isLoading" size="small" />

      <template v-else-if="search !== ''">
        <div v-if="partExists">
          <div class="part-result">
            <img :src="match.thumbnail_url" alt="" />
            <p>{{ match.no }}</p>
            <p><DecodeString :string="match.name" /></p>
            <button type="button" @click="addPart">
              Add to List
              <AddIcon viewBox="0 0 16 16" aria-hidden />
            </button>
          </div>
        </div>
        <div v-else>
          <p>Part number {{search}} could not be found.</p>
        </div>
      </template>

      <div>
        <ul class="parts-results">
          <li class="part-result" v-for="part in partsList" :key="part" :data-part-no="part">
            <img :src="part.thumbnail_url" alt="" />
            <p>{{ part.no }}</p>
            <p><DecodeString :string="part.name" /></p>
            <button type="button" @click="removePart(part.no)">
              Remove
              <DeleteIcon viewBox="0 0 16 16" aria-hidden />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </form>
</template>

<script>
import AddIcon from 'bootstrap-icons/icons/plus-circle-fill.svg';
import DeleteIcon from 'bootstrap-icons/icons/trash-fill.svg';
import debounce from 'lodash/debounce';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { getItem } from '@/services/bricklink';
import DecodeString from '@/components/DecodeString.vue';

export default {
  name: 'PartsSearch',

  components: {
    AddIcon,
    DeleteIcon,
    LoadingSpinner,
    DecodeString,
  },

  data() {
    return {
      isLoading: false,
      partExists: false,
      search: '',
      match: undefined,
      partsList: [...this.items],
    };
  },

  watch: {
    items(items) {
      this.partsList = [...items];
    },
  },

  props: {
    items: Array,
  },

  emits: ['update:items'],

  methods: {
    addPart() {
      const partsList = [...this.partsList];

      if (!partsList.includes(this.match)) {
        partsList.push(this.match);
      }

      this.search = '';
      this.update(partsList);
    },

    removePart(partNo) {
      const partsList = [...this.partsList].filter((p) => p.no !== partNo);

      this.update(partsList);
    },

    update(list) {
      this.$emit(
        'update:items',
        list,
      );
    },

    async searchParts() {
      this.isLoading = true;

      await this.getPart();
    },

    getPart: debounce(function () { // eslint-disable-line func-names
      if (this.search.length < 1) {
        this.match = undefined;
        return;
      }

      getItem(this.search.trim())
        .then(({ data }) => {
          if (data.no !== '') {
            this.partExists = true;
            this.match = data;
          } else {
            this.partExists = false;
          }
        }).finally(() => {
          this.isLoading = false;
        });
    }, 250),
  },
};
</script>

<style lang="scss" scoped>

.parts-results {
  margin: 1rem 0;
  padding: 0;
}

.part-result {
  margin: 0 0 1rem;
  padding: 0;
  display: grid;
  grid-template:
    "image no   button" auto
    "image name button" auto
    / 50px 1fr;
  justify-content: start;
  align-items: center;
  gap: .25rem;

  img {
    grid-area: image;
    width: 100%;
    height: auto;
  }

  p {
    margin: 0;
    grid-area: no;
    font-weight: bold;
  }

  p:nth-child(3) {
    grid-area: name;
    font-weight: normal;
  }
}
</style>
