<template>
  <div class="part-result">
    <img :src="thumbnail_url" alt="" />
    <div>
      <p><DecodeString :string="name" /></p>
      <p><b>{{ no }}</b> | <span>{{ color }}</span> <span>({{ condition }})</span></p>
    </div>
    <button v-if="showButton" type="button" @click="addPart">
      Add to List
      <AddIcon viewBox="0 0 16 16" aria-hidden />
    </button>
  </div>
</template>

<script>
import AddIcon from 'bootstrap-icons/icons/plus-circle-fill.svg';
import DecodeString from '@/components/DecodeString.vue';

export default {
  name: 'PartsSearchResult',

  components: {
    AddIcon,
    DecodeString,
  },

  props: {
    no: String,
    name: String,
    thumbnail_url: String,
    color: String,
    condition: String,
    showButton: {
      type: Boolean,
      default: true,
    },
  },

  created() {
    if (this.no && !(this.name)) {
      return false;
    }
    return true;
  },
};
</script>

<style lang="scss">
.part-result {
  margin: 0 0 1rem;
  padding: 0;
  display: grid;
  grid-template:
    "image no   button" auto
    "image name button" auto
    / 50px 1fr;
  justify-content: start;
  align-items: center;
  gap: .25rem;

  img {
    grid-area: image;
    width: 100%;
    height: auto;
  }

  > div {
    grid-area: no;
  }

  p {
    margin: 0;
    &:first-child {
      font-weight: bold;
    }
  }

  > p:nth-child(3) {
    grid-area: name;
    font-weight: normal;
  }
}
</style>
