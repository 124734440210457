<template>
  <div class="home">
    <div class="l-width">
      <h2>
        Select several parts to find LEGO&trade; colors they're all available
        in.
      </h2>
      <PartsSearch :items="items" @update:items="updateItems" />
    </div>

    <div v-if="isWorking && !isLoaded">
      <LoadingSpinner />
    </div>

    <template v-if="isLoaded">
      <div class="common-colors u-blocked-row" v-if="commonColors.length">
        <h2>{{ commonColors.length }} Common Colors</h2>
        <ul class="common-colors" v-if="commonColors.length">
          <li v-for="c in commonColors" :key="c">
            <ColorDetail :color="{ color_id: c }" />
          </li>
        </ul>
      </div>

      <div class="similar-colors u-blocked-row" v-if="similarColors.length">
        <h2>{{ similarColors.length }} Similar Color Pairs</h2>

        <ColorExclusions/>
        <ul>
          <li class="similar-color__pair" v-for="c in similarColors" :key="c">
            <p>{{ pct(c.factor) }} Similar</p>
            <ColorDetail :color="{ color_id: c.c1 }" />
            <ColorDetail :color="{ color_id: c.c2 }" />
          </li>
        </ul>
      </div>

      <div class="items-list u-blocked-row" v-if="items.length">
        <h2>Additional Item Colors</h2>
        <ul v-if="items.length">
          <li v-for="i in items" :key="i">
            <PartDetail :item="i" />
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { getItem } from '@/services/bricklink';
import PartDetail from '@/components/PartDetail.vue';
import ColorDetail from '@/components/ColorDetail.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import PartsSearch from '@/components/PartsSearch.vue';
import ColorExclusions from '@/components/ColorExclusions.vue';

export default {
  name: 'HomeView',

  components: {
    PartDetail,
    ColorDetail,
    LoadingSpinner,
    PartsSearch,
    ColorExclusions,
  },

  computed: {
    ...mapState([
      'commonColors',
      'items',
      'isWorking',
      'isLoaded',
      'excludeChromeColors',
      'excludeMetallicColors',
      'excludeTransparentColors',
    ]),

    ...mapGetters(['similarColors']),
  },

  methods: {
    ...mapActions([
      'getColorsByItem',
      'getColors',
      'setItems',
      'toggleExcludeChromeColors',
      'toggleExcludeMetallicColors',
      'toggleExcludeTransparentColors',
    ]),

    updateItems(e) {
      this.setItems(e);
      this.idsToRoute();
    },

    parseIds() {
      this.getColorsByItem();
      this.idsToRoute();
    },

    idsToRoute() {
      const ids = this.items.map((part) => part.no).join(',');

      this.$router.replace({
        name: this.$route.name,
        query: { ids },
      });
    },

    pct(num) {
      return `${Math.round(num * 100)}%`;
    },
  },

  async created() {
    this.getColors();

    const uri = window.location.search.replace(/^\?/, '');
    const params = new URLSearchParams(uri);
    const ids = params.get('ids') || '';

    if (ids) {
      const justIds = ids.trim().split(',');

      Promise.all(
        justIds.map((id) => getItem(id).then(({ data }) => data)),
      ).then((items) => {
        this.setItems(items);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: grid;
  gap: 2rem;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.u-blocked-row {
  padding: 2rem 1rem;
  border-radius: 0.5rem;
  background: #eee;

  h2 {
    margin: 0 0 2rem;
  }
}

.common-colors {
  margin: 0;
  text-align: center;

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7.5rem, 1fr));
    gap: 1rem;
  }

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.similar-colors {
  ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 20rem));
    gap: 1rem;
  }
}

.similar-color__pair {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: stretch;
  justify-content: stretch;
  align-items: stretch;
  gap: 0.5rem;

  p {
    margin-bottom: 0.25rem;
    grid-column-end: span 2;
    text-align: center;
  }
}

.items-list {
  text-align: center;

  ul {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @media (min-width: 50rem) {
      grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
      align-items: start;
      justify-content: center;
    }
  }

  li {
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 3px -1px #000;
    background: #fff;
  }
}
</style>
