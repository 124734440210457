/* eslint-disable */
import { getItem } from '@/services/bricklink';

export const favoriteParts = ['32064', '87087', '26604', '47905', '4733', '32952'];

export const getAllFavoriteParts = () =>
  Promise.all(favoriteParts.map(async (partId) => await getItem(partId).then(({ data }) => data)));

export const sampleCart = {
  cart: {
    items: [
      {
        itemName: 'Technic, Link Chain',
        invDescription: 'Discounted rate for pre-packed bags of 50 pieces',
        invID: 239630812,
        invQty: 24850,
        bulkQty: 50,
        superlotID: 0,
        superlotQty: 1,
        salePercent: 0,
        itemType: 'P',
        invNew: 'Used',
        invComplete: '',
        colorID: 11,
        colorName: 'Black',
        itemNo: '3711',
        itemSeq: 0,
        itemID: 684,
        itemStatus: 'A',
        smallImg: '//img.bricklink.com/ItemImage/PT/11/3711.t1.png',
        largeImg: '//img.bricklink.com/ItemImage/PN/11/3711.png',
        invPrice: 'US $0.15',
        salePrice: 'US $0.15',
        nativePrice: 'US $0.15',
        invTierQty1: 0,
        invTierPrice1: 'US $0.00',
        invTierSalePrice1: 'US $0.00',
        invTierNativeSalePrice1: 'US $0.00',
        invTierQty2: 0,
        invTierPrice2: 'US $0.00',
        invTierSalePrice2: 'US $0.00',
        invTierNativeSalePrice2: 'US $0.00',
        invTierQty3: 0,
        invTierPrice3: 'US $0.00',
        invTierSalePrice3: 'US $0.00',
        invTierNativeSalePrice3: 'US $0.00',
        priceVatExcl: {
          invPrice: 'US $0.00',
          salePrice: 'US $0.00',
          nativePrice: 'US $0.00',
          invTierPrice1: 'US $0.00',
          invTierSalePrice1: 'US $0.00',
          invTierNativeSalePrice1: 'US $0.00',
          invTierPrice2: 'US $0.00',
          invTierSalePrice2: 'US $0.00',
          invTierNativeSalePrice2: 'US $0.00',
          invTierPrice3: 'US $0.00',
          invTierSalePrice3: 'US $0.00',
          invTierNativeSalePrice3: 'US $0.00',
        },
        priceVat: {
          salePrice: 'US $0.15',
          nativePrice: 'US $0.15',
        },
        cartQty: 300,
        cartBindQty: 1,
        invDate: '2023-10-03',
        invASCAvailable: true,
        sourceType: 4,
        sourceName: 'Oct 2023 Parts',
        invAvailable: 'Y',
        itemInStockroom: false,
        itemReservedForOther: false,
        warnings: [],
        totalWeightOunces: 0.95,
        totalWeightGrams: '27.00',
        totalPrice: 'US $45.00',
        totalSalePrice: 'US $45.00',
        totalNativePrice: 'US $45.00',
        totalNativeSalePrice: 'US $45.00',
        totalNativeSalePriceRaw: 45,
      },
      {
        itemName: 'Technic Turntable 28 Tooth, Top',
        invDescription: 'BLACK',
        invID: 67637533,
        invQty: 126,
        bulkQty: 1,
        superlotID: 0,
        superlotQty: 1,
        salePercent: 0,
        itemType: 'P',
        invNew: 'Used',
        invComplete: '',
        colorID: 11,
        colorName: 'Black',
        itemNo: '99010',
        itemSeq: 0,
        itemID: 105739,
        itemStatus: 'A',
        smallImg: '//img.bricklink.com/ItemImage/PT/11/99010.t1.png',
        largeImg: '//img.bricklink.com/ItemImage/PN/11/99010.png',
        invPrice: 'US $1.00',
        salePrice: 'US $1.00',
        nativePrice: 'US $1.00',
        invTierQty1: 0,
        invTierPrice1: 'US $0.00',
        invTierSalePrice1: 'US $0.00',
        invTierNativeSalePrice1: 'US $0.00',
        invTierQty2: 0,
        invTierPrice2: 'US $0.00',
        invTierSalePrice2: 'US $0.00',
        invTierNativeSalePrice2: 'US $0.00',
        invTierQty3: 0,
        invTierPrice3: 'US $0.00',
        invTierSalePrice3: 'US $0.00',
        invTierNativeSalePrice3: 'US $0.00',
        priceVatExcl: {
          invPrice: 'US $0.00',
          salePrice: 'US $0.00',
          nativePrice: 'US $0.00',
          invTierPrice1: 'US $0.00',
          invTierSalePrice1: 'US $0.00',
          invTierNativeSalePrice1: 'US $0.00',
          invTierPrice2: 'US $0.00',
          invTierSalePrice2: 'US $0.00',
          invTierNativeSalePrice2: 'US $0.00',
          invTierPrice3: 'US $0.00',
          invTierSalePrice3: 'US $0.00',
          invTierNativeSalePrice3: 'US $0.00',
        },
        priceVat: {
          salePrice: 'US $1.00',
          nativePrice: 'US $1.00',
        },
        cartQty: 1,
        cartBindQty: 1,
        invDate: '2023-10-03',
        invASCAvailable: true,
        sourceType: 4,
        sourceName: 'Oct 2023 Parts',
        invAvailable: 'Y',
        itemInStockroom: false,
        itemReservedForOther: false,
        warnings: [],
        totalWeightOunces: 0.08,
        totalWeightGrams: '2.34',
        totalPrice: 'US $1.00',
        totalSalePrice: 'US $1.00',
        totalNativePrice: 'US $1.00',
        totalNativeSalePrice: 'US $1.00',
        totalNativeSalePriceRaw: 1,
      },
      {
        itemName: 'Tile, Modified 2 x 3 with 2 Open O Clips',
        invDescription: 'Red',
        invID: 67524590,
        invQty: 372,
        bulkQty: 1,
        superlotID: 0,
        superlotQty: 1,
        salePercent: 0,
        itemType: 'P',
        invNew: 'New',
        invComplete: '',
        colorID: 5,
        colorName: 'Red',
        itemNo: '30350b',
        itemSeq: 0,
        itemID: 117858,
        itemStatus: 'A',
        smallImg: '//img.bricklink.com/ItemImage/PT/5/30350b.t1.png',
        largeImg: '//img.bricklink.com/ItemImage/PN/5/30350b.png',
        invPrice: 'US $0.09',
        salePrice: 'US $0.09',
        nativePrice: 'US $0.09',
        invTierQty1: 0,
        invTierPrice1: 'US $0.00',
        invTierSalePrice1: 'US $0.00',
        invTierNativeSalePrice1: 'US $0.00',
        invTierQty2: 0,
        invTierPrice2: 'US $0.00',
        invTierSalePrice2: 'US $0.00',
        invTierNativeSalePrice2: 'US $0.00',
        invTierQty3: 0,
        invTierPrice3: 'US $0.00',
        invTierSalePrice3: 'US $0.00',
        invTierNativeSalePrice3: 'US $0.00',
        priceVatExcl: {
          invPrice: 'US $0.00',
          salePrice: 'US $0.00',
          nativePrice: 'US $0.00',
          invTierPrice1: 'US $0.00',
          invTierSalePrice1: 'US $0.00',
          invTierNativeSalePrice1: 'US $0.00',
          invTierPrice2: 'US $0.00',
          invTierSalePrice2: 'US $0.00',
          invTierNativeSalePrice2: 'US $0.00',
          invTierPrice3: 'US $0.00',
          invTierSalePrice3: 'US $0.00',
          invTierNativeSalePrice3: 'US $0.00',
        },
        priceVat: {
          salePrice: 'US $0.09',
          nativePrice: 'US $0.09',
        },
        cartQty: 3,
        cartBindQty: 1,
        invDate: '2023-10-03',
        invASCAvailable: true,
        sourceType: 4,
        sourceName: 'Oct 2023 Parts',
        invAvailable: 'Y',
        itemInStockroom: false,
        itemReservedForOther: false,
        warnings: [],
        totalWeightOunces: 0.1,
        totalWeightGrams: '2.97',
        totalPrice: 'US $0.27',
        totalSalePrice: 'US $0.27',
        totalNativePrice: 'US $0.27',
        totalNativeSalePrice: 'US $0.27',
        totalNativeSalePriceRaw: 0.27,
      },
      {
        itemName: 'Tile, Modified 2 x 3 Pentagonal',
        invDescription: 'RED',
        invID: 213227983,
        invQty: 226,
        bulkQty: 1,
        superlotID: 0,
        superlotQty: 1,
        salePercent: 0,
        itemType: 'P',
        invNew: 'New',
        invComplete: '',
        colorID: 5,
        colorName: 'Red',
        itemNo: '22385',
        itemSeq: 0,
        itemID: 141671,
        itemStatus: 'A',
        smallImg: '//img.bricklink.com/ItemImage/PT/5/22385.t1.png',
        largeImg: '//img.bricklink.com/ItemImage/PN/5/22385.png',
        invPrice: 'US $0.29',
        salePrice: 'US $0.29',
        nativePrice: 'US $0.29',
        invTierQty1: 0,
        invTierPrice1: 'US $0.00',
        invTierSalePrice1: 'US $0.00',
        invTierNativeSalePrice1: 'US $0.00',
        invTierQty2: 0,
        invTierPrice2: 'US $0.00',
        invTierSalePrice2: 'US $0.00',
        invTierNativeSalePrice2: 'US $0.00',
        invTierQty3: 0,
        invTierPrice3: 'US $0.00',
        invTierSalePrice3: 'US $0.00',
        invTierNativeSalePrice3: 'US $0.00',
        priceVatExcl: {
          invPrice: 'US $0.00',
          salePrice: 'US $0.00',
          nativePrice: 'US $0.00',
          invTierPrice1: 'US $0.00',
          invTierSalePrice1: 'US $0.00',
          invTierNativeSalePrice1: 'US $0.00',
          invTierPrice2: 'US $0.00',
          invTierSalePrice2: 'US $0.00',
          invTierNativeSalePrice2: 'US $0.00',
          invTierPrice3: 'US $0.00',
          invTierSalePrice3: 'US $0.00',
          invTierNativeSalePrice3: 'US $0.00',
        },
        priceVat: {
          salePrice: 'US $0.29',
          nativePrice: 'US $0.29',
        },
        cartQty: 1,
        cartBindQty: 1,
        invDate: '2023-10-03',
        invASCAvailable: true,
        sourceType: 4,
        sourceName: 'Oct 2023 Parts',
        invAvailable: 'Y',
        itemInStockroom: false,
        itemReservedForOther: false,
        warnings: [],
        totalWeightOunces: 0.02,
        totalWeightGrams: '0.6',
        totalPrice: 'US $0.29',
        totalSalePrice: 'US $0.29',
        totalNativePrice: 'US $0.29',
        totalNativeSalePrice: 'US $0.29',
        totalNativeSalePriceRaw: 0.29,
      },
    ],
    superlots: [],
    totalItems: 305,
    totalLots: 4,
    totalPrice: 'US $46.56',
    totalNativePrice: 'US $46.56',
    totalWarnings: 0,
    totalPriceRaw: '46.56',
    totalNativePriceRaw: '46.5600',
    totalWeightGrams: '32.91',
    totalWeightOunces: '1.16',
    weightUnknownLots: 0,
    viewCurrency: 'US $',
    aveLotPrice: 'US $11.64',
  },
};
