/* eslint-disable */
import axios from 'axios';

const api = axios.create({
  baseURL: '/.netlify/functions/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

const ajaxApi = axios.create({
  baseURL: 'https://www.bricklink.com/_ajax/getSearchQuick.ajax',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export const searchParts = (partNumber) => {
  const params = new URLSearchParams();
  params.append('strQuery', partNumber);
  return ajaxApi.post('', params);
};

export const getItem = (itemId) =>
  api.post('item', {
    itemId,
  });

export const getItemColors = (itemId) =>
  api.post('itemColors', {
    itemId,
  });

export const getItemPriceGuide = ({ itemId, colorId, condition }) =>
  api.post('itemPriceGuide', {
    itemId,
    colorId,
    condition,
  });

export const getAllColors = (itemId) =>
  api.post('allColors', {
    itemId,
  });

export const getColor = (colorId) =>
  api.post('color', {
    colorId,
  });
