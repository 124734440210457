import Color from 'color';
import { fromHex } from 'find-color';

export const isColor = (c) => {
  if (!c) return false;
  if (!Color(c)) return false;

  const s = new Option().style;
  s.color = c;

  return Color(s.color).toString() === Color(c).toString();
};

export const getContrastingColor = (c) => (Color(c).luminosity() < 0.5
  ? '#fff'
  : '#000');

export const getShadowColor = (c) => (Color(c).luminosity() < 0.5
  ? '#000'
  : '#fff');

export const getColorSimilarity = (color1, color2) => {
  const c1 = Color(color1);
  const c2 = Color(color2);

  const c1h = c1.hue();
  const c2h = c2.hue();

  const c1s = c1.saturationl();
  const c2s = c2.saturationl();

  const c1l = c1.lightness();
  const c2l = c2.lightness();

  const hDiff = Math.abs(c1h - c2h) <= 180
    ? Math.abs(c1h - c2h)
    : 180 - (Math.abs(c1h - c2h) - 180);
  const sDiff = Math.abs(c1s - c2s);
  const lDiff = Math.abs(c1l - c2l);

  const hFactor = (180 - Math.min(hDiff, 180)) / 180;
  const sFactor = (100 - sDiff) / 100;
  const lFactor = (100 - lDiff) / 100;

  return hFactor * sFactor * lFactor;
};

export const findClosestColor = (userColor, availableColors) => {
  const nearest = fromHex(availableColors).find(userColor);
  return (nearest > -1 ? `#${nearest.toString(16).toUpperCase()}` : undefined);
};
