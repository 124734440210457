import { createApp } from 'vue';
import VueUniversalModal from 'vue-universal-modal';
import 'vue-universal-modal/dist/index.css';
import App from './App.vue';
import router from './router';
import store from './store';

createApp(App)
  .use(store)
  .use(router)
  .use(VueUniversalModal, {
    teleportTarget: '#modals',
  })
  .mount('#app');
