<template>
  <span class="sr-only">Content is loading&hellip;</span>
  <div class="spinner" :class="{
    'spinner--small': size === 'small',
    'spinner--medium': size === 'medium',
    'spinner--large': size === 'large',
  }"></div>
</template>

<script>
export default {
  name: 'LoadingSpinner',

  props: {
    size: {
      type: String,
      default: 'large',
    },
  },
};
</script>

<style lang="scss">
.spinner {
  --size: min(30vmin, 100px);
  --border-width: 1rem;

  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-areas: "spinner" auto / 100%;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
    grid-area: spinner;
  }

  &::after {
    content: "";
    display: block;
    height: var(--size);
    width: var(--size);
    grid-area: spinner;
    border: var(--border-width) solid #d11013;
    border-left-color: #f6ec35;
    border-radius: 100%;
    animation: spin linear 3s infinite;
  }
}

.spinner--medium {
  --size: min(20vmin, 75px);
  --border-width: 0.75rem;
}

.spinner--small {
  --size: min(10vmin, 50px);
  --border-width: 0.5rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
