<template>
  <div class="home">
    <div class="l-width">
      <h2>My Favorite Part Prices</h2>
      <p><i>Quick checks on utility / structural part prices from North American sellers.</i></p>
      <ul class="favorite-parts" v-if="favoriteParts && favoriteParts.length">
        <li class="favorite-part" v-for="f in favoriteParts" :key="f.no">
          <PartsSearchResult
            :no="f.no"
            :name="f.name"
            :thumbnail_url="f.thumbnail_url"
            :showButton="false"
          />
          <PartPriceGuide :no="f.no" />
        </li>
      </ul>
      <p v-else>No favorite parts at the moment.</p>
    </div>
  </div>

</template>

<script>
import { mapActions, mapState } from 'vuex';
import PartsSearchResult from '@/components/PartsSearchResult.vue';
import PartPriceGuide from '@/components/PartPriceGuide.vue';

export default {
  name: 'PricesView',

  components: {
    PartsSearchResult,
    PartPriceGuide,
  },

  computed: {
    ...mapState(['favoriteParts']),
  },
  methods: {
    ...mapActions(['getFavoriteParts']),
  },

  async created() {
    this.getFavoriteParts();
  },
};
</script>

<style lang="scss">
.favorite-parts {
  margin: 2rem 0;
  padding: 0
}

.favorite-part {
  margin-bottom: 2rem;
  list-style-type: none;
}
</style>
