<template>
  <div class="home">
    <div class="l-width">
      <h2>Cart Part Prices vs Average</h2>
      <div>
        <p>To get cart contents, here's the fairly hacky method of getting cart data.</p>
        <ol>
          <li>Open your cart page at BrickLink.</li>
          <li>Open your browser's DevTools and open the Network tab.</li>
          <li>Refresh the page.</li>
          <li>Click the row for <code>getStoreCart.ajax</code> and view its Response tab.</li>
          <li>Right-click in that Response and click Copy All.</li>
          <li>Paste that in the box below.</li>
        </ol>

        <form class="cart-form">
          <label for="cart">Cart JSON</label>
          <textarea id="cart" v-model="cart" rows="8"/>
        </form>
      </div>
      <div v-if="cartParts && cartParts.length">
        <h3>Total Savings: {{ totalSavings }}</h3>
        <button
          class="toggle"
          :class="{
            'toggle--is-on': onlyShowUsedParts,
          }"
          @click.prevent="toggleOnlyShowUsedParts"
          type="button"
          >Only Used</button>
        <ul>
          <li class="cart-part" v-for="cartPart in visibleCartParts" :key="cartPart.invID">
            <PartsSearchResult
              :no="cartPart.itemNo"
              :name="cartPart.itemName"
              :thumbnail_url="cartPart.largeImg"
              :showButton="false"
              :color="cartPart.colorName"
              :condition="cartPart.invNew"
            />
            <PartPriceGuide
              :no="cartPart.itemNo"
              :cart-price="stripPrice(cartPart.salePrice || cartPart.invPrice)"
              :cart-qty="cartPart.cartQty"
              :color-id="cartPart.colorID"
              :condition="cartPart.invNew"
            />
          </li>
        </ul>
        <div
          class="progress-meter"
          :style="{
            '--total-parts': cartParts.length,
          '--visible-parts': minPartsLength
          }">Showing {{ minPartsLength }} of {{ cartParts.length }} parts</div>
        <button v-if="showButton" @click="addVisibleParts">Show More Parts</button>
      </div>
      <p v-else>No cart parts at the moment.</p>
    </div>
  </div>

</template>

<script>
import PartsSearchResult from '@/components/PartsSearchResult.vue';
import PartPriceGuide from '@/components/PartPriceGuide.vue';
import { sampleCart } from '@/utilities/favoriteParts';

const sortNames = (a, b) => (a.itemName > b.itemName ? 1 : -1);

export default {
  name: 'PricesView',

  components: {
    PartsSearchResult,
    PartPriceGuide,
  },

  data() {
    return {
      cart: JSON.stringify(sampleCart, null, 2),
      onlyShowUsedParts: false,
      partsLength: 10,
    };
  },

  computed: {
    cartParts() {
      let cart = [];
      try {
        const unsortedCart = JSON.parse(this.cart);
        cart = unsortedCart.cart.items
          .sort(sortNames)
          .filter((item) => {
            if (this.onlyShowUsedParts) {
              return item.invNew === 'Used';
            }
            return true;
          });
      } catch (e) {
        console.error(e);
      }
      return cart;
    },

    minPartsLength() {
      return Math.min(this.partsLength, this.cartParts.length);
    },

    visibleCartParts() {
      return this.cartParts.slice(0, this.partsLength);
    },

    showButton() {
      return this.partsLength < this.cartParts.length;
    },

    totalSavings() {
      let total = 0;
      let sale = 0;
      this.cartParts.forEach((part) => {
        total += parseInt(this.stripPrice(part.totalPrice), 10);
        sale += parseInt(this.stripPrice(part.totalSalePrice), 10);
      });
      console.log({ total, sale });
      return `$${total - sale}`;
    },
  },

  methods: {
    stripPrice(price) {
      return price ? price.replace('US $', '') : '';
    },

    addVisibleParts() {
      this.partsLength = Math.min(this.cartParts.length, (this.partsLength + 10));
    },

    toggleOnlyShowUsedParts() {
      this.onlyShowUsedParts = !this.onlyShowUsedParts;
    },
  },
};
</script>

<style lang="scss">
.cart-parts {
  margin: 2rem 0;
  padding: 0
}

.cart-part {
  margin-bottom: 2rem;
  list-style-type: none;
}

ul,
ol {
  margin: 2rem 0;
  padding: 0;

  li {
    margin: 0 0 0.5rem;
  }
}

.progress-meter {
  margin: 1rem 0;
  min-height: 2rem;
  padding: 0.25rem 1rem;
  border: 2px solid #d11013;
  position: relative;
  overflow: hidden;
  text-align: center;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #f6ec35;
    transition: transform .15s ease-out;
    transform: translateX(calc(
      (var(--total-parts) - var(--visible-parts))
      / var(--total-parts)
      * -100%
    ));
    z-index: -1;
  }
}

[id="cart"] {
  font-family: monospace;
  font-size: 0.85rem;
  background-color: rgba(192, 192, 192, 0.25);
}
</style>
