<template>
  <div class="part-detail">
    <div class="part-detail__header">
      <h3 class="part-detail__title">{{item.no}} - <DecodeString :string= item.name /></h3>
      <div class="part-detail__image">
        <img :src="item.image_url" alt="" />
      </div>
      <button class="part-detail__toggle" type="button" @click="toggleCollapsed">
        <template v-if="isCollapsed">
          <span class="sr-only">Expand Colors</span>
          <ChevronDown />
        </template>
        <template v-else>
          <span class="sr-only">Collapse Colors</span>
          <ChevronUp />
        </template>
      </button>
    </div>
    <CollapseTransition :duration="600">
      <ul v-show="!isCollapsed" class="part-detail__colors" v-if="showColors">
        <li v-for="c in additionalColors" :key="c">
          <ColorDetail
            :color="c"
            :is-common="isCommonColor(c.color_id)"
            :is-similar="isSimilarColor(c.color_id)"
          />
        </li>
      </ul>
    </CollapseTransition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
import ChevronDown from 'bootstrap-icons/icons/chevron-down.svg';
import ChevronUp from 'bootstrap-icons/icons/chevron-up.svg';
import ColorDetail from '@/components/ColorDetail.vue';
import DecodeString from '@/components/DecodeString.vue';

export default {
  name: 'PartDetail',

  components: {
    CollapseTransition, ColorDetail, ChevronDown, ChevronUp, DecodeString,
  },

  data() {
    return {
      isCollapsed: true,
    };
  },

  props: {
    item: Object,
  },

  computed: {
    ...mapGetters(['similarColors']),

    ...mapState(['commonColors']),

    showColors() {
      return this.additionalColors && this.additionalColors.length;
    },

    additionalColors() {
      if (!this.commonColors.length) {
        return [...this.item.colors];
      }

      if (!this.item.colors.length) {
        return [...this.item.colors];
      }

      return [...this.item.colors];
    },
  },

  methods: {
    isCommonColor(colorID) {
      if (!this.commonColors.length) return false;

      return this.commonColors.includes(colorID);
    },

    isSimilarColor(colorID) {
      if (!this.similarColors.length) return false;

      return this.similarColors.some((color) => [color.c1, color.c2].includes(colorID));
    },

    toggleCollapsed() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>

<style lang="scss">
.part-detail {
  display: grid;
  grid-template:
    "header" 5rem
    "colors" auto /
     100%;
  align-items: center;
  gap: 1rem;
}

.part-detail__header {
  width: 100%;
  grid-area: header;
  display: grid;
  align-items: center;
  gap: inherit;
  grid-template:
    "image title toggle" auto /
    3rem 1fr 3rem;
}

.part-detail__title {
  margin: 0;
  max-height: 5rem;
  grid-area: title;
  text-align: left;
  overflow: scroll;
}

.part-detail__image {
  grid-area: image;

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.part-detail__toggle {
  height: 100%;
  grid-area: toggle;
  background: transparent;
  border: 0;

  svg, svg * {
    pointer-events: none;
  }

  &:hover {
    background-color: #eee;
  }
}

.part-detail__colors {
  margin: 0;
  padding: 0;
  grid-area: colors;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(6.5rem, 1fr));
  gap: .5rem;

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
</style>
