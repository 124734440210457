/* eslint-disable */
import { createStore } from 'vuex';

import { getAllColors, getItemColors } from '@/services/bricklink';
import { getAllFavoriteParts } from '@/utilities/favoriteParts';
import { getColorSimilarity, findClosestColor } from '@/utilities/color';

export default createStore({
  state: {
    colors: [],
    items: [],
    commonColors: [],
    allSimilarColors: [],
    allClosestColors: [],
    foundColor: undefined,
    isWorking: false,
    isLoaded: false,
    excludeTransparentColors: false,
    excludeChromeColors: false,
    excludeMetallicColors: false,
    favoriteParts: [],
  },

  getters: {
    transparentColorCodes(state) {
      return state.colors
        .filter(({ color_name: name }) => name.indexOf('Trans-') > -1)
        .map(({ color_id: id }) => id);
    },

    chromeColorCodes(state) {
      return state.colors
        .filter(({ color_name: name }) => name.indexOf('Chrome') > -1)
        .map(({ color_id: id }) => id);
    },

    metallicColorCodes(state) {
      return state.colors
        .filter(({ color_name: name }) => name.indexOf('Metallic') > -1)
        .map(({ color_id: id }) => id);
    },

    sortedItemColorLists(state) {
      if (!state.items.length) {
        return [];
      }

      if (!state.items.every((item) => item.colors.length)) {
        return [];
      }

      const itemColors = [...state.items]
        .sort((a, b) => {
          const sort = a.colors.length - b.colors.length;
          return sort;
        })
        .map((i) => i.colors.map((c) => c.color_id));

      return itemColors;
    },

    fewestColorItem(state, getters) {
      const { sortedItemColorLists } = getters;

      if (!sortedItemColorLists.length) {
        return [];
      }

      return [...sortedItemColorLists][0];
    },

    otherColorItems(state, getters) {
      const { sortedItemColorLists } = getters;

      if (!sortedItemColorLists.length) {
        return [];
      }

      return [...sortedItemColorLists].slice(1);
    },

    combinedColorItems(state, getters) {
      if (!getters.sortedItemColorLists.length) {
        return [];
      }

      const combinedList = getters.sortedItemColorLists.reduce(
        (acc, list) => [...acc, ...list],
        []
      );

      return [...new Set(combinedList)];
    },

    similarColors(state, getters) {
      const {
        allSimilarColors,
        excludeChromeColors,
        excludeMetallicColors,
        excludeTransparentColors,
      } = state;

      const { chromeColorCodes, metallicColorCodes, transparentColorCodes } = getters;

      return [...allSimilarColors].filter(({ c1, c2 }) => {
        if (
          excludeChromeColors &&
          (chromeColorCodes.includes(c1) || chromeColorCodes.includes(c2))
        ) {
          return false;
        }

        if (
          excludeMetallicColors &&
          (metallicColorCodes.includes(c1) || metallicColorCodes.includes(c2))
        ) {
          return false;
        }

        if (
          excludeTransparentColors &&
          (transparentColorCodes.includes(c1) || transparentColorCodes.includes(c2))
        ) {
          return false;
        }

        return true;
      });
    },

    closestColors(state, getters) {
      const {
        allClosestColors,
        excludeChromeColors,
        excludeMetallicColors,
        excludeTransparentColors,
      } = state;

      const { chromeColorCodes, metallicColorCodes, transparentColorCodes } = getters;

      return [...allClosestColors]
        .filter((c) => {
          if (excludeChromeColors && chromeColorCodes.includes(c.color_id)) {
            return false;
          }

          if (excludeMetallicColors && metallicColorCodes.includes(c.color_id)) {
            return false;
          }

          if (excludeTransparentColors && transparentColorCodes.includes(c.color_id)) {
            return false;
          }

          return true;
        })
        .slice(0, 6);
    },
  },

  mutations: {
    SET_ALL_COLORS(state, colors) {
      state.colors = colors;
    },

    SET_ITEMS_DATA(state, items) {
      state.items = items;
    },

    SET_WORKING(state) {
      state.isWorking = true;
    },

    SET_LOADED(state) {
      state.isLoaded = true;
    },

    UNSET_LOADED(state) {
      state.isLoaded = false;
    },

    SET_COMMON_COLORS(state, commonColors) {
      state.commonColors = commonColors;
    },

    SET_SIMILAR_COLORS(state, similarColors) {
      state.allSimilarColors = similarColors;
    },

    SET_USER_COLOR(state, userColor) {
      state.userColor = userColor;
    },

    SET_CLOSEST_COLORS(state, allClosestColors) {
      state.allClosestColors = allClosestColors;
    },

    SET_FOUND_COLOR(state, foundColor) {
      state.foundColor = foundColor;
    },

    TOGGLE_EXCLUDE_CHROME_COLORS(state) {
      state.excludeChromeColors = !state.excludeChromeColors;
    },

    TOGGLE_EXCLUDE_METALLIC_COLORS(state) {
      state.excludeMetallicColors = !state.excludeMetallicColors;
    },

    TOGGLE_EXCLUDE_TRANSPARENT_COLORS(state) {
      state.excludeTransparentColors = !state.excludeTransparentColors;
    },

    SET_FAVORITE_PARTS(state, favoriteParts) {
      state.favoriteParts = favoriteParts;
    },
  },

  actions: {
    setItems({ commit, dispatch }, parts) {
      commit('SET_WORKING');
      commit('UNSET_LOADED');
      commit('SET_ITEMS_DATA', parts);

      dispatch('getColorsByItem');
    },

    getColorsByItem({ commit, dispatch, state }) {
      return Promise.all(
        state.items.map(async (item) => ({
          ...item,
          colors: await getItemColors(item.no).then(({ data }) => data),
        }))
      ).then((items) => {
        commit('SET_ITEMS_DATA', items);
        commit('SET_LOADED');
        dispatch('setCommonColors');
      });
    },

    getColors({ commit, state }) {
      console.log('getColors', state.colors.length);
      if (state.colors.length < 1) {
        console.log('getColors, fresh request');
        getAllColors().then(({ data }) => commit('SET_ALL_COLORS', data));
      }
    },

    setCommonColors({ commit, dispatch, getters }) {
      const { fewestColorItem, otherColorItems } = getters;

      if (!fewestColorItem.length) return;

      if (!otherColorItems.length) return;

      const commonColors = fewestColorItem.filter((id) => {
        const itemsWithColor = otherColorItems.filter((ic) => ic.includes(id));

        return itemsWithColor.length === otherColorItems.length;
      });

      commit('SET_COMMON_COLORS', commonColors);

      dispatch('setSimilarColors');
    },

    setSimilarColors({ commit, getters, state }) {
      const { colors, commonColors } = state;

      const { combinedColorItems, sortedItemColorLists } = getters;

      const comparisons = {};

      combinedColorItems.map((i) =>
        combinedColorItems.map((j) => {
          if (i === j) return false;

          const k = [i, j].sort((a, b) => a - b);
          const color1 = colors.find((c) => c.color_id === i);
          const color2 = colors.find((c) => c.color_id === j);

          if (!color1 || !color2) return false;

          const colorItemListsWithSimilarColor = sortedItemColorLists.filter(
            (list) => list.includes(color1.color_id) || list.includes(color2.color_id)
          );

          if (sortedItemColorLists.length !== colorItemListsWithSimilarColor.length) {
            return false;
          }

          if (commonColors.includes(color1.color_id) || commonColors.includes(color2.color_id)) {
            return false;
          }

          const similarity = getColorSimilarity(`#${color1.color_code}`, `#${color2.color_code}`);

          if (similarity > 0.667) {
            comparisons[k.join('|')] = similarity;
          }

          return j;
        })
      );

      const allSimilarColors = [...Object.entries(comparisons)]
        .map((entry) => {
          const [k, factor] = entry;
          const [c1, c2] = k.split('|');
          return {
            c1: parseInt(c1, 10),
            c2: parseInt(c2, 10),
            factor,
          };
        })
        .sort((a, b) => b.factor - a.factor);

      commit('SET_SIMILAR_COLORS', allSimilarColors);
    },

    getClosestColors({ commit, state }, userColor) {
      const { colors } = state;

      const allClosestColors = colors
        .map((c) => {
          const similarity = getColorSimilarity(userColor, `#${c.color_code}`);

          const color = {
            ...c,
            similarity,
          };

          return color;
        })
        .filter((c) => c.similarity > 0.6)
        .sort((a, b) => b.similarity - a.similarity);

      commit('SET_CLOSEST_COLORS', allClosestColors);
    },

    findClosestColors({ commit, state }, userColor) {
      const found = findClosestColor(
        userColor,
        state.colors.map((c) => `#${c.color_code}`)
      );

      if (found) {
        const color = state.colors.find((c) => found.indexOf(c.color_code.toUpperCase()) > -1);

        commit('SET_FOUND_COLOR', color);
      }
    },

    toggleExcludeChromeColors({ commit }) {
      commit('TOGGLE_EXCLUDE_CHROME_COLORS');
    },

    toggleExcludeMetallicColors({ commit }) {
      commit('TOGGLE_EXCLUDE_METALLIC_COLORS');
    },

    toggleExcludeTransparentColors({ commit }) {
      commit('TOGGLE_EXCLUDE_TRANSPARENT_COLORS');
    },

    getFavoriteParts({ commit }) {
      getAllFavoriteParts().then((parts) => {
        commit('SET_FAVORITE_PARTS', parts);
      });
    },
  },

  modules: {},
});
