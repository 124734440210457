<template>
  <div class="exclusions">
    <button
    class="toggle"
    :class="{
      'toggle--is-on': excludeTransparentColors,
    }"
    @click.prevent="toggleExcludeTransparentColors"
    type="button"
    >Hide Transparent</button>

    <button
    class="toggle"
    :class="{
      'toggle--is-on': excludeMetallicColors,
    }"
    @click.prevent="toggleExcludeMetallicColors"
    type="button"
    >Hide Metallic</button>

    <button
    class="toggle"
    :class="{
      'toggle--is-on': excludeChromeColors,
    }"
    @click.prevent="toggleExcludeChromeColors"
    type="button"
    >Hide Chrome</button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ColorExclusions',

  computed: {
    ...mapState([
      'excludeChromeColors',
      'excludeMetallicColors',
      'excludeTransparentColors',
    ]),
  },

  methods: {
    ...mapActions([
      'toggleExcludeChromeColors',
      'toggleExcludeMetallicColors',
      'toggleExcludeTransparentColors',
    ]),
  },
};
</script>

<style lang="scss">
.exclusions {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  margin-bottom: 1rem;
  gap: 1rem;
}

.toggle {
  padding: 0.5rem;
  appearance: none;
  display: inline-grid;
  grid-auto-flow: column;
  grid-area: initial;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: 1px solid currentColor;
  color: #d11013;
  background-color: #fff;
  border-radius: 0.25rem;
  text-align: center;
  font-family: inherit;
  font-size: 0.75rem;
  letter-spacing: 0.0625em;

  &::before {
    content: '';
    display: inline-block;
    grid-area: 1 / 1 / 2 / 1;
    height: 1em;
    width: 1em;
    border-radius: 0.25em;
    border: 1px solid currentColor;
  }

  &:hover,
  &:active {
    box-shadow: 0 0 0 1px currentColor;
  }

  &:focus {
    outline: 1px solid #d11013;
    outline-offset: 2px;
  }
}

.toggle--is-on {
  box-shadow: 0 0 0 1px currentColor;

  &::before {
    background-color: #f6ec35;
  }

  &::after {
    content: '';
    grid-area: 1 / 1 / 2 / 1;
    height: 0.75em;
    width: 0.5em;
    border: 3px solid currentColor;
    border-width: 0 3px 3px 0;
    transform: translate(0.25em, -0.0625em) rotate(45deg) scale(1);
    position: relative;
  }
}
</style>
