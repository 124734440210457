<template>
  <header>
    <h1>LEGO&trade; Color Tools</h1>
    <nav>
      <router-link to="/">Parts</router-link>
      <router-link to="/search">Find Colors</router-link>
      <router-link to="/prices">Favorite Prices</router-link>
      <router-link to="/cart-prices">Cart Prices</router-link>
    </nav>
  </header>
  <main><router-view/></main>
  <footer>
    <p>&copy; 2022 <a href="https://jamessteinbach.com">James Steinbach</a>. All Rights Reserved.</p>
    <p class="fine-print">All data provided by <a href="https://bricklink.com">Bricklink</a>.<br />
    LEGO and BrickLink are trademarks of the LEGO Group.</p>
  </footer>
</template>

<style lang="scss">
*,
::before,
::after {
  box-sizing: border-box;
}

html {
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  color: #000;
  background: #fff;
}

body {
  margin: 0;
}

[id="app"] {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

header {
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: #f6ec35;
  text-align: center;

  h1 {
    margin: 0;
  }

  nav {
    margin-top: .5rem;
    display: flex;
    gap: .25rem;
    justify-content: center;

    a {
      display: inline-block;
      padding: 0.125rem .25rem;
      text-decoration: none;
      color: #d11013;
      border-top: .125rem solid transparent;
      border-bottom: .125rem solid transparent;

      &.router-link-active {
        border-bottom-color: currentColor;
      }

      &:hover,
      &:active {
        color: #8a0b0d;
      }

      &:focus {
        outline: .125rem solid currentColor;
      }
    }
  }
}

main {
  padding: 0 1rem 2rem;
}

footer {
  padding: 2rem 1rem;
  background-color: #f6ec35;
  text-align: center;

  > * {
    margin: 0 auto 1rem;
    max-width: 30rem;
  }
}

.fine-print {
  font-size: 0.75em;

  &:last-child {
    margin-bottom: 0;
  }
}

.button {
  padding: 1rem;
  appearance: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d11013;
  color: #fff;
  border: 0;
  border-radius: .5rem;
  text-align: center;
  text-transform: uppercase;
  font-family: inherit;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: .0625em;

  &:hover,
  &:active {
    background: #8a0b0d;
  }

  &:focus {
    box-shadow: 0 0 0 2px #f6ec35;
    outline: 1px solid #d11013;
    outline-offset: 1px;
  }
}

form {
  margin: 1rem auto;
  width: 100%;
  max-width: 30rem;
  display: flex;
  grid-template-columns: 100%;
  flex-direction: column;
  gap: .25rem;
}

label {
  display: block;
  font-weight: bold;
}

input, textarea {
  margin: 0.25rem 0 1rem;
  padding: 0.5rem;
  display: block;
  width: 100%;
  border: 0;
  border-radius: .5rem;
  border: 1px solid #d11013;
  background: #fff;
  font-size: 1rem;
  font-family: inherit;
  box-shadow: 0 0 3px -1px #000;

  &:hover,
  &:active {
    border-color: #8a0b0d;
  }

  &:focus {
    box-shadow: 0 0 0 2px #f6ec35;
    outline: 1px solid #d11013;
    outline-offset: 1px;
  }
}

button {
  padding: .5rem;
  display: inline-grid;
  grid-auto-flow: column;
  gap: .5rem;
  align-items: center;
  justify-content: center;
  grid-area: button;
  text-align: center;
  appearance: none;
  color: #d11013;
  background: #fff;
  border: 2px solid currentColor;
  border-radius: .25rem;

  * {
    fill: currentColor;
  }
}

code {
  padding: 0 0.25rem;
  font-size: 1.33em;
  background: rgba(192, 192, 192, .25);
  border-radius: 0.25rem;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.l-width {
  margin: 1rem auto;
  width: 100%;
  max-width: 30rem;
  display: grid;
  gap: 0;
}
</style>
