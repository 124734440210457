<template>
  <div
    v-if="allPriceGuide?.avg_price"
    class="part-price-guide"
    :class="diffClass(cartPrice, allPriceGuide.avg_price, cartQty)"
  >
    <p>
      <b>Total <br/> <span class="not-bold">{{ qty(cartQty) }} @ {{ usd(cartPrice) }}</span></b>
      <b>{{ usd(cartPrice * cartQty) }}</b>
    </p>
    <p>
      <span></span>
      <span>{{ pctDiff(cartPrice, allPriceGuide.avg_price) }}</span>
    </p>
    <p>
      <b>Any Color</b>
      <span>{{ usd(allPriceGuide.avg_price * cartQty) }}</span>
    </p>
    <p>
      <b>{{ diffLabel(cartPrice, allPriceGuide.avg_price)}}</b>
      <span>{{ usd(allPriceGuide.avg_price * cartQty - cartPrice * cartQty) }}</span>
    </p>
    <template v-if="colorPriceGuide?.avg_price">
      <p>
        <b>{{ getColorName(colorId) }}</b>
        <span>{{ usd(colorPriceGuide.avg_price * cartQty) }}</span>
      </p>
      <p>
        <b>{{ diffLabel(cartPrice, colorPriceGuide.avg_price)}}</b>
        <span>{{ usd(colorPriceGuide.avg_price * cartQty - cartPrice * cartQty) }}</span>
      </p>
    </template>
  </div>
  <p v-else-if="allPriceGuideLoaded && colorPriceGuideLoaded">Price data not found.
    <button @click="loadGuides">
      Try Again
      <ArrowClockwiseIcon viewBox="0 0 16 16" aria-hidden />
    </button>
  </p>
  <LoadingSpinner size="small" v-else />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getItemPriceGuide } from '@/services/bricklink';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import ArrowClockwiseIcon from 'bootstrap-icons/icons/arrow-clockwise.svg';

const formatQty = new Intl.NumberFormat('en-US');
const formatUSD = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export default {
  name: 'PartPriceGuide',

  components: {
    LoadingSpinner,
    ArrowClockwiseIcon,
  },

  data() {
    return {
      allPriceGuideLoaded: false,
      colorPriceGuideLoaded: false,
      allPriceGuide: {},
      colorPriceGuide: {},
    };
  },

  computed: {
    ...mapState(['colors']),
  },

  props: {
    no: String,
    cartQty: [String, Number],
    cartPrice: String,
    condition: String,
    colorId: [String, Number],
  },

  methods: {
    ...mapActions(['getColors']),

    async loadGuides() {
      if (this.no) {
        this.allPriceGuide = {};
        this.allPriceGuideLoaded = false;
        this.colorPriceGuideLoaded = false;
        const [allPriceGuide, colorPriceGuide] = await Promise.all([getItemPriceGuide({
          itemId: this.no,
          condition: this.condition,
        })
          .then(({ data }) => data)
          .catch((e) => console.log(e))
          .finally(() => {
            this.allPriceGuideLoaded = true;
          }),

        getItemPriceGuide({
          itemId: this.no,
          condition: this.condition,
          colorId: this.colorId,
        })
          .then(({ data }) => data)
          .catch((e) => console.log(e))
          .finally(() => {
            this.colorPriceGuideLoaded = true;
          }),
        ]);
        this.allPriceGuide = allPriceGuide;
        this.colorPriceGuide = colorPriceGuide;
      }
    },

    usd(price) {
      return formatUSD.format(price);
    },

    qty(qty) {
      return formatQty.format(qty);
    },

    pctDiff(cartPrice, avgPrice) {
      return `${Math.round(((cartPrice / avgPrice) - 1) * 100)}%`;
    },

    totalDiff(cartPrice, cartQty, avgPrice) {
      return this.usd((cartPrice - avgPrice) * cartQty);
    },

    diffClass(cartPrice, avgPrice, qty) {
      const absDiff = Math.abs((parseFloat(cartPrice) * qty) - (parseFloat(avgPrice) * qty));
      const relDiff = Math.abs(1 - (parseFloat(cartPrice) / parseFloat(avgPrice)));
      const direction = parseFloat(cartPrice) > parseFloat(avgPrice) ? 'red' : 'green';
      return (relDiff > 0.2 || absDiff > 1.5)
        ? direction
        : '';
    },

    diffLabel(cartPrice, avgPrice) {
      return parseFloat(cartPrice) > parseFloat(avgPrice) ? 'Extra Cost' : 'Savings';
    },

    getColorName(id) {
      return this.colors.find((c) => parseInt(c.color_id, 10) === parseInt(id, 10))?.color_name ?? 'This Color';
    },
  },

  created() {
    this.getColors();
    this.loadGuides();
  },
};
</script>

<style lang="scss">
.part-price-guide {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: start;
  align-items: start;
  gap: .5rem 1.5rem;
  border: 2px solid var(--accent-color, #ccc);
  border-radius: 0.25rem;

  p {
    margin: 0;
    padding: 0.25rem 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    font-size: 0.85rem;
  }

  .double {
    grid-column: 1 / -1;
  }

  .not-bold {
     font-weight: normal;
  }

  > p > :last-child {
    text-align: right;
  }

  &.red {
    --accent-color: #B71C1C;
  }

  &.green {
    --accent-color: #9E9D24;
  }
}
</style>
