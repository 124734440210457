import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import SearchView from '../views/SearchView.vue';
import PricesView from '../views/PricesView.vue';
import CartPricesView from '../views/CartPricesView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/search',
    name: 'search',
    component: SearchView,
  },
  {
    path: '/prices',
    name: 'prices',
    component: PricesView,
  },
  {
    path: '/cart-prices',
    name: 'cart-prices',
    component: CartPricesView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
