<template>
  <div
    class="part-color"
    :class="{
      'part-color--is-common': isCommon,
      'part-color--is-similar': isSimilar,
    }"
    :data-color-id="color.color_id"
  >
    <div class="part-color__preview" :style="colorVar">
      <h4>{{ name }}</h4>
      <p v-if="color.quantity">{{ color.quantity }} avail.</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { getContrastingColor, getShadowColor } from '@/utilities/color';

export default {
  name: 'ColorDetail',

  props: {
    color: Object,
    isCommon: Boolean,
    isSimilar: Boolean,
  },

  computed: {
    ...mapState(['colors']),

    colorData() {
      const id = parseInt(this.color.color_id, 10);
      const foundColor = this.colors.find((c) => c.color_id === id);
      return (
        foundColor || {
          color_name: this.color.color_name,
          color_code: this.color.color_code,
        }
      );
    },

    name() {
      return this.colorData.color_name;
    },

    code() {
      return this.colorData.color_code;
    },

    colorVar() {
      return {
        '--bg': `#${this.code || '000'}`,
        '--fg': getContrastingColor(`#${this.code || '000'}`),
        '--shadow': getShadowColor(`#${this.code || '000'}`),
      };
    },
  },
};
</script>

<style lang="scss">
.part-color {
  margin: 0 auto;
  width: 100%;
  max-width: 10rem;
  position: relative;
}

.part-color--is-common {
  &::before {
    content: 'Common';
    padding: 0.25em 0.5em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.5rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.0625em;
    background-color: #d11013;
    color: #fff;
    z-index: 1;
  }
}

.part-color--is-similar {
  &::after {
    content: 'Similar';
    padding: 0.25em 0.5em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.5rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.0625em;
    background-color: #f6ec35;
    color: #000;
    z-index: 1;
  }
}

.part-color__preview {
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
  grid-auto-rows: auto;
  align-items: center;
  justify-content: center;
  aspect-ratio: 16/9;
  background: var(--bg);
  color: var(--fg);
  text-shadow: 0px 3px 6px var(--shadow);
  text-align: center;
  border: 0.25rem #fff solid;
  border-radius: 0.25rem;
  box-shadow: 0 0 4px -2px #000;

  h4 {
    margin: 0.25rem;
    font-size: 0.75rem;
  }

  p {
    margin: 0.25rem;
    font-size: 0.67rem;
  }

  @media (min-width: 30rem) {
    aspect-ratio: 7/5;

    h4 {
      font-size: 0.875rem;
    }

    p {
      font-size: 0.75rem;
    }
  }
}
</style>
