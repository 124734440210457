<template>
  <span>{{ decodedString }}</span>
</template>

<script>
import { decode } from 'html-entities';

export default {
  name: 'DecodeString',

  props: {
    string: String,
  },

  computed: {
    decodedString() {
      return decode(this.string);
    },
  },
};
</script>
